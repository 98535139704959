
import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';

const ServiceLayout = ({ children }) => (
  <>

    <Header navPosition="right" className="reveal-from-bottom" />
    <main className="site-content">
        <div class="container-fluid my-5 py-3">
        <h1>We are slightly good!</h1>
            <div className="row">
{/* tab */}
                <div className="col-12 col-md-3  p-0"> <ul class="tabContainer p-0">
                        <li class="tabItem">
                      <Link to="/webdev"  className="button  btn-apri button-wide-mobile button-sm" >Web Development</Link>
                        </li>
                        <li class="tabItem">
                      <Link to="/appdev"  className="button btn-apri button-wide-mobile button-sm" >Application Development</Link>
                        </li>
                        <li class="tabItem">
                      <Link to="/social"  className="button btn-apri button-wide-mobile button-sm" >Social Media Marketing</Link>
                        </li>
                        <li class="tabItem">
                      <Link to="/graphic"  className="button btn-apri button-wide-mobile button-sm" >Graphic Design & Video Editing</Link>
                        </li>
                        <li class="tabItem">
                      <Link to="/finalexpense"  className="button btn-apri button-wide-mobile button-sm" >Final Expense Leads Generation</Link>
                        </li>
                        <li class="tabItem">
                      <Link to="/solar"  className="button btn-apri button-wide-mobile button-sm" >Solar Leads Generation</Link>
                        </li>
                    </ul>
                </div>
{/* tab ends */}
{/* panels starts */}
                <div className="col-12 col-md-9 ">
{/* panel 1 */}

      {children}
                    
{/* panel1 ends */}
                </div>
{/* panel ends */}
            </div>
        </div>
    </main>
    <Footer />
  </>
);

export default ServiceLayout;  
