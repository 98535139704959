import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import Modal from "../elements/Modal";
// import Slick from "../elements/slick"
 import {Slider} from "../elements/slider"
import MousePara from '../elements/mousepara'

const propTypes = {
    ...SectionProps.types
};

const defaultProps = {
    ...SectionProps.defaults
};

const Hero = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {
    const [videoModalActive, setVideomodalactive] = useState(false);

    const openModal = e => {
        e.preventDefault();
        setVideomodalactive(true);
    };

    const closeModal = e => {
        e.preventDefault();
        setVideomodalactive(false);
    };

    const outerClasses = classNames(
        "hero section center-content",
        topOuterDivider && "has-top-divider",
        bottomOuterDivider && "has-bottom-divider",
        hasBgColor && "has-bg-color",
        invertColor && "invert-color",
        className
    );

    const innerClasses = classNames(
        "hero-inner section-inner",
        topDivider && "has-top-divider",
        bottomDivider && "has-bottom-divider"
    );
    return (
        <section {...props} className={outerClasses} id="sadaHero" style={{position:"relative"}}>
            <div className="container-sm">
                <div className={innerClasses}>

                    <div className="hero-content">
                        <MousePara style={{zIndex:-9}}/>
                        <h1
                            className="mt-0 mb-16 reveal-from-bottom"
                            data-reveal-delay="200"
                        >
                            Apricopt{" "}
                            <span
                                className="text-color-primary"
                                style={{ color: "grey" }}
                            >
                                Tech
                            </span>
                        </h1>
                        <div className="container-xs">
                            <p
                                className="m-0 mb-32 reveal-from-bottom"
                                data-reveal-delay="400"
                            >Apricopt providing reliable, quality services and ensuring lasting customer satisfaction. Provide
high-quality, cost-effective, and hands-on practical assistance to all of our clients on all software
development requirements and Bpo services.</p>
                            <div
                                className="reveal-from-bottom"
                                data-reveal-delay="600"
                            >
                                <ButtonGroup>
                                    <Button
                                        tag="a"
                                        color="dark"
                                        wideMobile
                                        href="/quotation"
                                    >
                                        See Pricing
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                    <div
                        className="hero-figure reveal-from-bottom illustration-element-01"
                        data-reveal-value="20px"
                        data-reveal-delay="800"
                    >
                        <div className="sadaSlider">
<Slider />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
