import React, { Component } from "react";
import Slider from "react-slick";



import Background1 from '../../assets/images/slide/xlide1.jpg';
import Background2 from '../../assets/images/slide/xlide2.jpg';
import Background3 from '../../assets/images/slide/xlide3.jpg';

export default class Fade extends Component {
  render() {
    const settings = {
      dots: true,
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
            <img src={Background1} />
          </div>
          <div>
            <img src={Background2} />
          </div>
          <div>
            <img src={Background3} />
          </div>
        </Slider>
      </div>
    );
  }
}


