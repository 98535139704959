

import React from 'react'
import ServiceLayout from '../layouts/serviceLayout'


export default function AppDev(props) {

    return (
        <ServiceLayout>
            <h1>Application Development</h1>
            <p>Apricopt Tech is dedicated to delivering high-quality apps that are built to scale from the ground up using current industry standards. experience in creating high performing, digitally transformative, and feature-packed native mobile applications for Android and iOS devices.
									Apricopt can also build cross-platform mobile applications that will work on any device or platform. Companies and organizations choosing Appnovation for their custom mobile application development needs can be assured that their final deliverable, no matter the technology it's
									 built on, will be secure, scalable, and sustainable in whatever environment it's hosted.</p>
            <h3>Application services include:</h3>
											<ul class="service-list">
												<li>We build hybrid apps as well as fully native apps</li>
												<li>Web based backends are built connect users of your apps</li>
												<li>Free UI/UX design</li>
												<li>one month free maintainance with us</li>
											</ul>
        </ServiceLayout>

    )
}


