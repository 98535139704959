import React from "react";
import { Helmet } from "react-helmet";

function Quotation() {
  return (
    <div className="container my-5 pt-5">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Apricopt| Quotation</title>
        <meta
          name="description"
          content="Do you want to get a cheap website and other IT services checkout apricopt's best. Getting a business website was never so easy"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://apricopt.com/quotation" />
        <meta
          name="keywords"
          content="cheap website, softwarehouse, price, webdevelopment"
        />
        <meta name="author" content="zohaib ramzan" />
      </Helmet>
      {/*Section: Content*/}
      <section className="dark-grey-text">
        {/* Section heading */}
        <h3 className="text-center font-weight-bold mb-5">Our Plans</h3>
        {/* Grid row */}
        <div className="row">
          <div className="col-12 mb-4">
            <div className="card z-depth-0 bordered border-light">
              <div className="card-body p-0">
                <div className="row mx-0">
                  <div className="col-md-8 grey lighten-4 rounded-left pt-4">
                    <h5 className="font-weight-bold">
                      Bussiness static website
                    </h5>
                    <p className="font-weight-light text-muted mb-4">
                      These type of websites are perfect for the small startups
                      to the mid level bussiness companies.We provide free
                      lifetime web hosting for static websites.
                    </p>
                    <div className="d-flex technos justify-content-center  flex-wrap my-0">
                      <p className="h6 text-success mx-1 ">Technologies:</p>
                      <p className="h6 mx-1 text-info">HTML</p>
                      <p className="h6 mx-1 text-info">CSS</p>
                      <p className="h6 mx-1 text-info">Bootstrap</p>
                      <p className="h6 mx-1 text-info">JavaScript</p>
                    </div>
                  </div>

                  <div className="col-md-4 text-center pt-2 bg-apri">
                    <p className="h4 font-weight-normal text-white">
                      Starting from
                    </p>
                    <p className="h1 font-weight-normal priceTag text-white">
                      $90
                    </p>
                    <p className="h5 font-weight-light text-white  b-4">
                      Free Hosting
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-4">
            <div className="card z-depth-0 bordered border-light">
              <div className="card-body p-0">
                <div className="row mx-0">
                  <div className="col-md-8 grey lighten-4 rounded-left pt-4">
                    <h5 className="font-weight-bold">
                      Bussiness dynamic website
                    </h5>
                    <p className="font-weight-light text-muted mb-4">
                      Dynamic website enable customers to send orders online.
                      Admin can update the services on the website by using
                      Content Management System provided.
                    </p>
                    <div className="d-flex technos flex-wrap justify-content-center my-0">
                      <p className="h6 text-success mx-1 ">Technologies:</p>
                      <p className="h6 mx-1 text-info">React</p>
                      <p className="h6 mx-1 text-info">Reactstrap</p>
                      <p className="h6 mx-1 text-info">JavaScript</p>
                      <p className="h6 mx-1 text-info">Nodejs</p>
                    </div>
                  </div>
                  <div className="col-md-4 text-center pt-2 bg-apri">
                    <p className="h4 font-weight-normal text-white">
                      Starting from
                    </p>
                    <p className="h1 font-weight-normal priceTag text-white">
                      $160
                    </p>
                    <p className="h5 font-weight-light text-white  b-4">
                      Free CMS
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 mb-4">
            <div className="card z-depth-0 bordered border-light">
              <div className="card-body p-0">
                <div className="row mx-0">
                  <div className="col-md-8 grey lighten-4 rounded-left pt-4">
                    <h5 className="font-weight-bold">Ecommerce Store</h5>
                    <p className="font-weight-light text-muted mb-4">
                      Have your own ecommerce store and join the market of the
                      future.Customer can register/login, Can place online order
                      and pay online. Get all advance features for your
                      ecommerce store.
                    </p>
                    <div className="d-flex flex-wrap  technos justify-content-center my-0">
                      <p className="h6 text-success mx-1 ">Technologies:</p>
                      <p className="h6 mx-1 text-info">React</p>
                      <p className="h6 mx-1 text-info">Reactstrap</p>
                      <p className="h6 mx-1 text-info">Strapi</p>
                      <p className="h6 mx-1 text-info">Nodejs</p>
                      <p className="h6 mx-1 text-info">expressJS</p>
                    </div>
                  </div>
                  <div className="col-md-4 text-center pt-2 bg-apri">
                    <p className="h4 font-weight-normal text-white">
                      Starting from
                    </p>
                    <p className="h1 font-weight-normal priceTag text-white">
                      $220
                    </p>
                    <p className="h5 font-weight-light text-white  b-4">
                      Unlimited Scalability
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 mb-4">
            <div className="card z-depth-0 bordered border-light">
              <div className="card-body p-0">
                <div className="row mx-0">
                  <div className="col-md-8 grey lighten-4 rounded-left pt-4">
                    <h5 className="font-weight-bold">Hosting Services</h5>
                    <p className="font-weight-light text-muted mb-4">
                      Get your very own server running on platforms. Host your
                      websites , Save your precious data , set your IP Cameras
                      and all your IOT needs.
                    </p>
                    <div className="d-flex flex-wrap technos justify-content-center my-0">
                      <p className="h6 text-success mx-1 ">Platforms:</p>
                      <p className="h6 mx-1 text-info">Amazon Web Services</p>
                      <p className="h6 mx-1 text-info">Google Cloud Platform</p>
                      <p className="h6 mx-1 text-info">Digital Ocean</p>
                      <p className="h6 mx-1 text-info">Heroku</p>
                    </div>
                  </div>
                  <div className="col-md-4 text-center pt-2 bg-apri">
                    <p className="h3 font-weight-normal text-white">
                      Starting from
                    </p>
                    <p className="h4 font-weight-normal priceTag text-white">
                      $10(service Charges /month)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-4">
            <div className="card z-depth-0 bordered border-light">
              <div className="card-body p-0">
                <div className="row mx-0">
                  <div className="col-md-8 grey lighten-4 rounded-left pt-4">
                    <h5 className="font-weight-bold">Website update</h5>
                    <p className="font-weight-light text-muted mb-4">
                      Well.Updating your website content and design was not so
                      easy. We provide reliable price for website modifications.
                    </p>
                  </div>
                  <div className="col-md-4 text-center pt-2 bg-apri">
                    <p className="h3 font-weight-normal text-white">
                      Starting from
                    </p>
                    <p className="h4 font-weight-normal priceTag text-white">
                      $100
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-4">
            <div className="card z-depth-0 bordered border-light">
              <div className="card-body p-0">
                <div className="row mx-0">
                  <div className="col-md-8 grey lighten-4 rounded-left pt-4">
                    <h5 className="font-weight-bold">
                      Social Media marketting & SEO services
                    </h5>
                    <p className="font-weight-light text-muted mb-4">
                      Get potential customers on all social media platforms and
                      improve google ranking of your ranking.{" "}
                    </p>
                    <div className="d-flex technos flex-wrap justify-content-center my-0">
                      <p className="h6 text-success mx-1 ">Techniques:</p>
                      <p className="h6 mx-1 text-info">onPage optimization</p>
                      <p className="h6 mx-1 text-info">offPage optimization</p>
                      <p className="h6 mx-1 text-info">
                        Manage social media accounts
                      </p>
                      <p className="h6 mx-1 text-info">Paid Campaign</p>
                    </div>
                  </div>
                  <div className="col-md-4 text-center pt-2 bg-apri">
                    <p className="h4 font-weight-normal text-white">
                      Starting from
                    </p>
                    <p className="h2 font-weight-normal priceTag text-white">
                      $170/month
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-4">
            <div className="card z-depth-0 bordered border-light">
              <div className="card-body p-0">
                <div className="row mx-0">
                  <div className="col-md-8 grey lighten-4 rounded-left pt-4">
                    <h5 className="font-weight-bold">Mobile Application</h5>
                    <p className="font-weight-light text-muted mb-4">
                      Lets operate your bussiness on mobile and hit your
                      potential customers.
                    </p>
                    <div className="d-flex technos justify-content-center my-0">
                      <p className="h6 text-success mx-1 ">Technologies:</p>
                      <p className="h6 mx-1 text-info">Android Studio</p>
                      <p className="h6 mx-1 text-info">Swift</p>
                      <p className="h6 mx-1 text-info">java</p>
                      <p className="h6 mx-1 text-info">Kotlin</p>
                      <p className="h6 mx-1 text-info">React-Native</p>
                      <p className="h6 mx-1 text-info">Flutter</p>
                    </div>
                  </div>
                  <div className="col-md-4 text-center pt-2 bg-apri">
                    <p className="h4 font-weight-normal text-white">
                      Starting from
                    </p>
                    <p className="h1 font-weight-normal priceTag text-white">
                      $400
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*row khatam */}
        </div>
        {/* Grid row */}
      </section>
      {/*Section: Content*/}
    </div>
  );
}
export default Quotation;
