import React, { useRef, useEffect } from 'react';
import { useLocation, Switch , Route } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';

//bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'
// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import Contact from './views/contact'
import Portfolio from './views/portfolio'
import Quotation from './views/quotation'
import Services from './views/servies'
import Career from './views/career'
import WebDev from './views/webdev'
import AppDev from './views/appdev'
import Social from './views/social'
import Solar from './views/solar'
import FinalExpense from './views/finalexpense'
import Graphic from './views/graphic'

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = page => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {

  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded')
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/contact" component={Contact} layout={LayoutDefault} />
          <AppRoute exact path="/portfolio" component={Portfolio} layout={LayoutDefault} />
          <AppRoute exact path="/quotation" component={Quotation} layout={LayoutDefault} />
          <AppRoute exact path="/career" component={Career} layout={LayoutDefault} />
          <Route exact path="/webdev" component={WebDev}  />
          <Route exact path="/appdev" component={AppDev}  />
          <Route exact path="/social" component={Social}  />
          <Route exact path="/solar" component={Solar}  />
          <Route exact path="/finalexpense" component={FinalExpense}  />
          <Route exact path="/graphic" component={Graphic}  />
        </Switch>
      )} />
  );
}

export default App;
