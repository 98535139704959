


import React from 'react'
import ServiceLayout from '../layouts/serviceLayout'


export default function Solar(props) {

    return (
        <ServiceLayout>
            <h2>Solar Leads Generation/Live transfer</h2>
            <p>Modernize transforms the solar installation experience for both homeowners and contractors. We generate solar install leads through our high traffic digital marketing channels and set real-time Appointments with Homeowners, Our homeowners are seeking to connect with qualified solar professionals for their solar installation projects in real-time.</p>
<h3> Apricopt Offer </h3>
            <ul class="service-list">
                                    <li>Real-time Solar Appointments with interested Homeowners having a credit score above 660 points/</li>
                                    <li> Live transfer close sales in real-time.</li>
                                    <li> All Data generate through social media campaign Exclusive 100%TCPA Complaint</li></ul> 
        </ServiceLayout>

    )
}


