import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import AboutUs from '../components/sections/Aboutus'
import Testimonial from '../components/sections/Testimonial';
import Slick from '../components/elements/slick'
import Cta from '../components/sections/Cta';
import Team from '../components/sections/team.js'
import {Helmet} from "react-helmet";

const Home = () => {

  return (
    <>
     <Helmet>
                <meta charSet="utf-8" />
                <title>Apricopt|Home</title>
                <meta name="description" content="Apricopt is an american based software house company.Mostly known for their web development , Mobile development and big online systems creation. Founded by two brothers zohaib and zayn."/>
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://apricopt.com/" />
                <meta name="keywords" content="web development, best softwarehouse in pakistan, software house in islamabad , apricopt software house , apricopt , android application"/>
                <meta name="author" content="zohaib ramzan"/>
     </Helmet>


      <Hero className="illustration-section-01" />
        <AboutUs hasBgColor={true} />
      <FeaturesTiles />
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      {/* <Testimonial topDivider /> */}
        {/* <Team /> */}
    </>
  );
}

export default Home;
