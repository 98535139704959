import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Image from '../components/elements/Image'

//import FooterSocial from '../components/layouts/partials/FooterSocial.js';

const axios = require("axios");
function Career() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [resume, setResume] = useState("");
  const [message, setMessage] = useState("");

  const [contacted, setContacted] = useState(false);

  function handleChange(e) {
    switch (e.target.name) {
      case "name":
        setName(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "contact":
        setContact(e.target.value);
        break;
      case "message":
        setMessage(e.target.value);
    }
  }
    
function handleFile(e) {
    console.log(e.target)
setResume(e.target.files[0]);
}



  function handleSubmit(e) {
    e.preventDefault();
    console.log("g mai agya submit honnu");

      axios({
        method: 'post',
        url: 'https://strapi.apricopt.com/jobs',
        data: {
          fullname: name,
          email: email,
          phoneNumber : contact,
          message : message
        }
      }).then(response => {
        if(response.status == 200 ) {
            let formdata = new FormData();
            formdata.append('files', resume)
            formdata.append('ref' , 'job');
formdata.append('field' , 'resume');
formdata.append('refId' , response.data.id);
            axios.post(`https://strapi.apricopt.com/upload` , formdata).then(data => console.log(data)).catch(err => console.log(err))
          setContacted(true);
          setName("");
          setEmail("");
          setResume("");
          setContact("");
          setMessage("");

        }
      });
  }



  

  return (
    <div className="container py-5  my-5">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Apricopt|Career</title>
        <meta
          name="description"
          content="Contact apricopt for job opportunities or for if you want to get queries about your project. Getting in touch will increase the chance for you and us to create a relation."
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://apricopt.com/career" />
        <meta
          name="keywords"
          content="contact apricopt, email address apricopt, best software house in islamabad"
        />
        <meta name="author" content="zohaib ramzan" />
      </Helmet>
      {/*Section: Content*/}
      <section className="contact-section dark-grey-text mb-5">
        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n      \t.contact-section .form .btn-floating {\n          left: 3px;\n        }\n      ",
          }}
        />
          <h1>Join Our Passionate Team</h1>
        {/* Form with header */}
          <div className="card" style={{ background: "#222426" }}>
          {/* Grid row */}
          <div className="row">
            {/* Grid column */}
            <div className="col-lg-8">
              <div className="card-body form">
                {/* Header */}
                <h6 className="font-weight-bold text-white dark-grey-text mt-4">
                  <i className="fas text-white fa-envelope pr-2 mr-1" />
                    We are always looking for talented individuals:

                </h6>
                <h5 className="font-weight-bold text-white dark-grey-text ">
                  <i className="fas text-white fa-envelope pr-2 mr-1" />
                    Yes you are if you think so

                </h5>
                {/* Grid row */}
                <div className="row">
                  {/* Grid column */}
                  <div className="col-md-6">
                    <div className="md-form mb-0">
                      <input
                        type="text"
                        id="form-contact-name"
                        className="form-control my-1"
                        placeholder="Full name "
                        name="name"
                        onChange={handleChange}
                        value={name}
                      />
                    </div>
                  </div>
                  {/* Grid column */}
                  {/* Grid column */}
                  <div className="col-md-6">
                    <div className="md-form mb-0">
                      <input
                        type="text"
                        id="form-contact-email"
                        className="form-control my-1"
                        placeholder="Email Address"
                        name="email"
                        onChange={handleChange}

                        value={email}
                      />
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
                {/* Grid row */}
                <div className="row">
                  {/* Grid column */}
                  <div className="col-md-12">
                    <div className="md-form mb-0">
                      <input
                        type="text"
                        id="form-contact-phone"
                        className="form-control my-1"
                        placeholder="Contact number"
                        name="contact"
                        onChange={handleChange}
                        value={contact}
                      />
                    </div>
                  </div>
                  {/* Grid column */}
                  {/* Grid column */}
                    <div className="col-md-4">

                          <label class="my-2 text-white" htmlFor="resume">Drop Your Resume</label>
                    </div>
                  <div className="col-md-8">
                      <div className="md-form mb-0">
                      <input
                        type="file" 
                        id="form-contact-company"
                        className=" my-1 bg-success "
                        name="resume"
                        onChange={handleFile}
                      />
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
                {/* Grid row */}
                <div className="row">
                  {/* Grid column */}
                  <div className="col-md-12">
                    <div className="md-form mb-0">
                      <textarea
                        id="form-contact-message"
                        className="form-control md-textarea  my-1"
                        rows={3}
                        defaultValue={""}
                        placeholder="Why you are applying give us few words..."
                        name="message"
                        onChange={handleChange}
                        value={message}
                      />
                      <a
                        className="btn-floating btn-sm btn mt-1 float-right btn-info blue"
                        onClick={handleSubmit}
                      >
                        <i className="far fa-paper-plane" />
                          Apply Now
                      </a>
                    </div>
                  </div>
                  {contacted ? <div>  <span style={{color:"wheat"}}>Your message has been sucessfully recieved! You will recieve a response within next few hours</span>
                 </div> : <> </> }
                  
                
                  {/* Grid column */}
                </div>
                {/* Grid row */}
              </div>
            </div>
            {/* Grid column */}
            {/* Grid column */}
            <div className="col-lg-4 d-flex justify-content-center align-items-center">
                <Image src={require('../assets/images/img-02.png')} width={300} height={300} />
            </div>
              
            {/* Grid column */}
          </div>
          {/* Grid row */}
        </div>
        {/* Form with header */}
      </section>
    </div>
  );
}

export default Career;
