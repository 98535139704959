
import React from 'react'
import ServiceLayout from '../layouts/serviceLayout'


export default function WebDev(props) {

    return (
        <ServiceLayout>

            <h1>Website Development</h1>
            <p>Apricopt Tech offers the best website development services can help your business stay one step ahead of the curve and ensure success in the online world. Client-focused, customer-centric, creating website solutions that deliver tangible business results, Appnovation's web developers help brands the ever-changing digital landscape.</p>
            <h3>Web services include:</h3>
            <ul class="service-list">
												<li>We use Headless CMS to have one backend to manage all the data</li>
                                                <li>We ensure secure APIs and database with best security practices</li>
												<li>We are great at managing the servers on platforms like amazon web services</li>
												<li>We built Progressive Web Apps , Server Side Websites.</li>
												<li>We mostly use the tools that are hot in the market. unless the customer demand us to use different one</li>
												<li>Yes We write clean and maintainable code for client side as well as backend </li>
												<li>We provide compelete documentation of your website so you can own it properly.</li>
												<li>When it comes to maintaining the website we don't keep charging for changes. We just believe that our customer is our biggest asset</li>
                                                <li>At the end you will get a great online presence with apricopt.</li>
											</ul>
            
            </ServiceLayout>

    )
}


