import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import {Link} from "react-router-dom"

const propTypes = {
    ...SectionTilesProps.types
};

const defaultProps = {
    ...SectionTilesProps.defaults
};
const FeaturesTiles = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
}) => {
    const outerClasses = classNames(
        "features-tiles section",
        topOuterDivider && "has-top-divider",
        bottomOuterDivider && "has-bottom-divider",
        hasBgColor && "has-bg-color",
        invertColor && "invert-color",
        className
    );

    const innerClasses = classNames(
        "features-tiles-inner section-inner pt-0",
        topDivider && "has-top-divider",
        bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames(
        "tiles-wrap center-content",
        pushLeft && "push-left"
    );

    const sectionHeader = {
        title: "We make perfect bussiness solutions",
        paragraph:
            ""
    };

    return (
        <section {...props} className={outerClasses}>
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader
                        data={sectionHeader}
                        className="center-content mt-5"
                    />
                    <div className={tilesClasses} >
                        <div className="tiles-item reveal-from-bottom">
                            <div className="tiles-item-inner serviceCard p-2 ">
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16">
                                        <Image
                                            src={require("./../../assets/images/feature-tile-icon-01.svg")}
                                            alt="Features tile icon 01"
                                            width={64}
                                            height={64}
                                        />
                                    </div>
                                </div>
                                <div className="features-tiles-item-content">
                                        <Link to="/webdev">
                                    <h4 className="mt-0 mb-8 serviceText">
                                         Website Development

                                    </h4>
                                        </Link>
                                    <p className="m-0 text-sm">Getting your online presence is important these days. Get Modern web application spinning on the internet.</p>
                                </div>
                            </div>
                        </div>

                        <div
                            className="tiles-item reveal-from-bottom"
                            data-reveal-delay="200"
                        >
                            <div className="tiles-item-inner  serviceCard p-2">
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16">
                                        <Image
                                            src={require("./../../assets/images/feature-tile-icon-02.svg")}
                                            alt="Features tile icon 02"
                                            width={64}
                                            height={64}
                                        />
                                    </div>
                                </div>
                                <div className="features-tiles-item-content">
                                    <Link to="/solar">

                                    <h4 className="mt-0 mb-8">Solar Leads Generation/Live transfer</h4>
                                    </Link>
                                    <p className="m-0 text-sm">Modernize transforms the solar installation experience for both homeowners and contractors.</p>
                                </div>
                            </div>
                        </div>

                        <div
                            className="tiles-item reveal-from-bottom"
                            data-reveal-delay="400"
                        >
                            <div className="tiles-item-inner serviceCard p-2">
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16">
                                        <Image
                                            src={require("./../../assets/images/feature-tile-icon-03.svg")}
                                            alt="Features tile icon 03"
                                            width={64}
                                            height={64}
                                        />
                                    </div>
                                </div>
                                <div className="features-tiles-item-content">
                                    <Link to="/finalexpense">

                                    <h4 className="mt-0 mb-8">Final expense Leads Generation</h4>
                                    </Link>
                                    <p className="m-0 text-sm">The key to maximizing your potential in the final
expense market is having access to a steady flow of fresh leads.</p>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-bottom">
                            <div className="tiles-item-inner serviceCard p-2">
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16">
                                        <Image
                                            src={require("./../../assets/images/feature-tile-icon-04.svg")}
                                            alt="Features tile icon 04"
                                            width={64}
                                            height={64}
                                        />
                                    </div>
                                </div>
                                <div className="features-tiles-item-content">
                                    <Link to="/appdev">
                                    <h4 className="mt-0 mb-8">
                                       Mobile App Development
                                    </h4>

                                    </Link>
                                    <p className="m-0 text-sm">Having app for your bussiness is very important.Native applications to Hybrid one.</p>
                                </div>
                            </div>
                        </div>

                        <div
                            className="tiles-item reveal-from-bottom"
                            data-reveal-delay="200"
                        >
                            <div className="tiles-item-inner serviceCard p-2">
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16">
                                        <Image
                                            src={require("./../../assets/images/feature-tile-icon-05.svg")}
                                            alt="Features tile icon 05"
                                            width={64}
                                            height={64}
                                        />
                                    </div>
                                </div>
                                <div className="features-tiles-item-content">
                                    <Link to="/graphic">
                                    <h4 className="mt-0 mb-8">
                                    Graphic Design & Video Editing 
                                    </h4>

                                    </Link>
                                    <p className="m-0 text-sm">We provide complete graphic designing and video editing services for youtube channels of our customers and for other platforms.</p>
                                </div>
                            </div>
                        </div>

                        <div
                            className="tiles-item reveal-from-bottom"
                            data-reveal-delay="400"
                        >
                            <div className="tiles-item-inner serviceCard p-2">
                                <div className="features-tiles-item-header">
                                    <div className="features-tiles-item-image mb-16">
                                        <Image
                                            src={require("./../../assets/images/feature-tile-icon-06.svg")}
                                            alt="Features tile icon 06"
                                            width={64}
                                            height={64}
                                        />
                                    </div>
                                </div>
                                <div className="features-tiles-item-content">
                                    <Link to="/social">
                                    <h4 className="mt-0 mb-8">
                                    Social Media Marketing
                                    </h4>

                                    </Link>
                                    <p className="m-0 text-sm">Everyone is on social media so why not your bussiness?
                                    Our Social media experts manage all social media tasks for you.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
