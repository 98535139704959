



import React from 'react'
import ServiceLayout from '../layouts/serviceLayout'


export default function Social(props) {

    return (
        <ServiceLayout>
            <h2>Social media marketing </h2>
								<p>Apricopt Tech can help you grow your business and build brand awareness, get followers talking with promoted posts on Social Media platforms. 
                                    Social media marketing matters much more than you think. If you want to reach new customers and promote brand loyalty, connecting with digital communities is crucial. At Firetoss, we’re dedicated to generating buzz and engaging with your ideal audience, not just boosting your follower count. Whether you need a simple paid social media campaign or a fully-integrated strategy including paid ads and share-worthy posts, an experienced social media advertising agency has the skills and resources to help you shine.
                                    our team of social media specialists is full of social butterflies who love capturing online attention with relevant, meaningful campaigns.</p>
            <h3>Social Media Services:</h3>
											<ul class="service-list">
												<li>Your online presence is really important we know this really well</li>
												<li>We bring out best strategies to promote your business on social media platform</li>
												<li>We have great team of social media marketing to increase engagement</li>
											</ul>
        </ServiceLayout>

    )
}


