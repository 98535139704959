import React, { useState } from "react";
import { Helmet } from "react-helmet";

//import FooterSocial from '../components/layouts/partials/FooterSocial.js';

const axios = require("axios");
function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");

  const [contacted, setContacted] = useState(false);

  function handleChange(e) {
    switch (e.target.name) {
      case "name":
        setName(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "contact":
        setContact(e.target.value);
        break;
      case "company":
        setCompany(e.target.value);
        break;
      case "message":
        setMessage(e.target.value);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    console.log("g mai agya submit honnu");

      axios({
        method: 'post',
        url: 'https://strapi.apricopt.com/contacts',
        data: {
          name: name,
          email: email,
          contact : contact,
          company: company ,
          message : message
        }
      }).then(response => {
        if(response.status == 200 ) {
          setContacted(true);
          setName("");
          setEmail("");
          setCompany("");
          setContact("");
          setMessage("");

        }
      });
  }



  

  return (
    <div className="container py-5  my-5">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Apricopt|Contact</title>
        <meta
          name="description"
          content="Contact apricopt for job opportunities or for if you want to get queries about your project. Getting in touch will increase the chance for you and us to create a relation."
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://apricopt.com/contact" />
        <meta
          name="keywords"
          content="contact apricopt, email address apricopt, best software house in islamabad"
        />
        <meta name="author" content="zohaib ramzan" />
      </Helmet>
      {/*Section: Content*/}
      <section className="contact-section dark-grey-text mb-5">
        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n      \t.contact-section .form .btn-floating {\n          left: 3px;\n        }\n      ",
          }}
        />
        {/* Form with header */}
          <div className="card" style={{ background: "#222426" }}>
          {/* Grid row */}
          <div className="row">
            {/* Grid column */}
            <div className="col-lg-8">
              <div className="card-body form">
                {/* Header */}
                <h3 className="font-weight-bold text-white dark-grey-text mt-4">
                  <i className="fas text-white fa-envelope pr-2 mr-1" />
                  Write to us:
                </h3>
                {/* Grid row */}
                <div className="row">
                  {/* Grid column */}
                  <div className="col-md-6">
                    <div className="md-form mb-0">
                      <input
                        type="text"
                        id="form-contact-name"
                        className="form-control my-1"
                        placeholder="Full name "
                        name="name"
                        onChange={handleChange}
                        value={name}
                      />
                    </div>
                  </div>
                  {/* Grid column */}
                  {/* Grid column */}
                  <div className="col-md-6">
                    <div className="md-form mb-0">
                      <input
                        type="text"
                        id="form-contact-email"
                        className="form-control my-1"
                        placeholder="Email Address"
                        name="email"
                        onChange={handleChange}
                        value={email}
                      />
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
                {/* Grid row */}
                <div className="row">
                  {/* Grid column */}
                  <div className="col-md-6">
                    <div className="md-form mb-0">
                      <input
                        type="text"
                        id="form-contact-phone"
                        className="form-control my-1"
                        placeholder="Contact number"
                        name="contact"
                        onChange={handleChange}
                        value={contact}
                      />
                    </div>
                  </div>
                  {/* Grid column */}
                  {/* Grid column */}
                  <div className="col-md-6">
                    <div className="md-form mb-0">
                      <input
                        type="text"
                        id="form-contact-company"
                        className="form-control my-1"
                        placeholder="Company name (optional)"
                        name="company"
                        onChange={handleChange}
                        value={company}
                      />
                    </div>
                  </div>
                  {/* Grid column */}
                </div>
                {/* Grid row */}
                {/* Grid row */}
                <div className="row">
                  {/* Grid column */}
                  <div className="col-md-12">
                    <div className="md-form mb-0">
                      <textarea
                        id="form-contact-message"
                        className="form-control md-textarea  my-1"
                        rows={3}
                        defaultValue={""}
                        placeholder="Your message"
                        name="message"
                        onChange={handleChange}
                        value={message}
                      />
                      <a
                        className="btn-floating btn-sm btn mt-1 float-right btn-info blue"
                        onClick={handleSubmit}
                      >
                        <i className="far fa-paper-plane" />
                        Send Message
                      </a>
                    </div>
                  </div>
                  {contacted ? <div>  <span style={{color:"wheat"}}>Your message has been sucessfully recieved! You will recieve a response within next few hours</span>
                 </div> : <> </> }
                  
                
                  {/* Grid column */}
                </div>
                {/* Grid row */}
              </div>
            </div>
            {/* Grid column */}
            {/* Grid column */}
            <div className="col-lg-4">
              <div className="card-body contact text-center h-100 text-white">
                <h3 className="font-weight-bold my-4 pb-2 text-white">
                  Contact information
                </h3>
                <ul className="text-lg-left list-unstyled ml-4">
                  <li>
                    <p>E mail: contact@apricopt.com</p>
                  </li>
                  <li>
                      <p><span className="bg-primary px-2"> Head Office:</span> 101 California Av 302 Santa Monica ca 90403</p>
                  </li>
                  <li>
                      <p><span className="bg-primary px-2"> Branch Office :</span>  Office #1 , 1st floor - plaza no 23 phase 4  DHA Lahore</p>
                  </li>
                  <li>
                    <p>Phone : 702-613-5548</p>
                  </li>
                  <li>
                    <p></p>
                  </li>
                </ul>
                <hr className="hr-light my-4" />
                <ul className="list-inline text-center list-unstyled">
                  <li
                    className="list-inline-item text-white"
                    style={{ color: "wheat" }}
                  >
                    <a
                      className="p-2 fa-lg tw-ic text-white"
                      style={{ color: "wheat" }}
                    >
                      <i className="fa  fa-twitter text-white" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a className="p-2 fa-lg li-ic">
                      <i className="fab fa-linkedin-in"> </i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a className="p-2 fa-lg ins-ic">
                      <i className="fab fa-instagram"> </i>
                    </a>
                  </li>
                </ul>
               
              </div>
            </div>
            {/* Grid column */}
          </div>
          {/* Grid row */}
        </div>
        {/* Form with header */}
      </section>
    </div>
  );
}

export default Contact;
