





import React from 'react'
import ServiceLayout from '../layouts/serviceLayout'


export default function Graphic(props) {

    return (
        <ServiceLayout>
            <h1>Graphic designing</h1>
            <p>Graphic design is a craft where professionals create visual content to communicate messages. Apricopt have creative group of graphic designers.</p>
            <h3>We offer:</h3>
            <ul>
                <li>
UI/UX Designs
                </li>
                <li>
                    Logo Designs
                </li>
                <li>
                    Brouchers
                </li>
                <li>
                    Custom Social Media Images
                </li>
                <li>
                    And Any thing you can think of :)
                </li>
            </ul>
        </ServiceLayout>

    )
}


