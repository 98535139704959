import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

function Portfolio() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch("https://strapi.apricopt.com/portfolios")
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  if (loading) {
    return <> </>;
  }

  return (
    <div className="container py-5  my-5">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Apricopt|Portfolios</title>
        <meta
          name="description"
          content="We have best websites design. Our developers work day and night to deliver the best to our clients.Here is our portfolio of amazing websites and other project we have done so far"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://apricopt.com/portfolio" />
        <meta
          name="keywords"
          content="portfolio apricopt, email address apricopt, best software house in islamabad"
        />
        <meta name="author" content="zohaib ramzan" />
      </Helmet>
      {/* Section: Block Content */}
      <section className="dark-grey-text text-center">
        <h3 className="font-weight-bold mb-4 pb-2">Our projects</h3>
        <p className="text-muted w-responsive mx-auto mb-5">
          We have listed few projects in our portfolio{" "}
        </p>
        {/* Grid row */}
        <div className="row">
          {data.map((item) => (
            <div className="col-lg-4 col-md-12 mb-4">
              <div className="view overlay rounded z-depth-1">
                <img
                  src={`https://strapi.apricopt.com${item.picture.url}`}
                  className="img-fluid"
                  alt="Sample project image"
                />
                <a>
                  <div className="mask rgba-white-slight" />
                </a>
              </div>
              <div className="px-3 pt-3 mx-1 mt-1 pb-0">
                <h4 className="font-weight-bold mt-1 mb-3">
                  {item.ProjectName}
                </h4>
                <p className="text-muted">{item.description}</p>
                <a className="btn btn-apri btn-sm btn-rounded" href={item.link}>
                  <i className="fas fa-clone left" /> View project
                </a>
              </div>
            </div>
          ))}

          {/* Grid column */}

          {/* grid column */}
        </div>
        {/* Grid row */}
      </section>
      {/* Section: Block Content */}
    </div>
  );
}
export default Portfolio;
