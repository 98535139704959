
import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import CoreStyles from 'react-awesome-slider/src/core/styles.scss';
import AnimationStyles from 'react-awesome-slider/src/styled/fall-animation/fall-animation.scss';


import withAutoplay from 'react-awesome-slider/dist/autoplay';

// importing for SimplerSlider 
import  { useState } from 'react';


import Background1 from '../../assets/images/slide/xlide1.jpg';
import Background2 from '../../assets/images/slide/xlide2.jpg';
import Background3 from '../../assets/images/slide/xlide3.jpg';







export function Slider() {
  // nothing logical just change the css to get the cube back

  const AutoplaySlider = withAutoplay(AwesomeSlider);

  const slider = (
    <AutoplaySlider
    className="aws-btn"
    play={true}
    cancelOnInteraction={false} // should stop playing on user interaction
    interval={5000}
    bullets={false}
      animation="foldOutAnimation"
      cssModule={[CoreStyles, AnimationStyles]}
    >
      <div data-src={Background1} />
      <div data-src={Background2}  />
      <div data-src={Background3} />
{/* <div data-src={Background4} /> */}
 


    </AutoplaySlider>
  );


  
  return (
    <div >
    {slider}
       </div>

  )
}








